// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getStorage, ref } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCJ-NicZg2f8fD6bKpcsLfLdHmCPr883uY",
  authDomain: "wholesale-management-db764.firebaseapp.com",
  projectId: "wholesale-management-db764",
  storageBucket: "wholesale-management-db764.appspot.com",
  messagingSenderId: "189357315341",
  appId: "1:189357315341:web:f586e074f96e7adad4cc44",
  measurementId: "G-Y8VKZY6V2N",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const auth = getAuth();
export const storage = getStorage(app);
export const storageRef = ref(storage);
export const imagesRef = ref(storage, "images");
export const db = getFirestore(app);
