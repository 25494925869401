import React, { useState, useEffect } from "react";
import { auth } from "../Firebase";
import { getUserByEmail } from "../services/Auth/User";
import AddToCart from "../services/Cart/AddToCart";

export default function Product(props) {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState();
  const [quantity, setQuantity] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      setEmail(auth.currentUser.email);
    }, 1000);
  });
  const showDetails = () => {
    setOpen(!open);
  };
  const addToCart = (e) => {
    e.preventDefault();
    e.stopPropagation();
    getUserByEmail(email)
      .then(function (response) {
        let userId = response[0].id;
        return userId;
      })
      .then(function (userId) {
        AddToCart(userId, props.name, props.price, quantity);
        setQuantity(0);
      });
  };
  return (
    <div>
      <div className="w-72 h-full">
        <div className="flex flex-col p-1 m-4 border border-1 rounded-lg">
          <div className="h-48 w-full">
            <img
              src={props.imgSrc}
              alt="product"
              className="object-contain h-48 w-full"
            />
          </div>
          <div className="flex flex-col p-1">
            <div className="flex justify-between py-2">
              <div className="text-lg font-bold">
                <h4>{props.name}</h4>
                <h3>₹{props.price}</h3>
              </div>
              <div
                className="border border-1 rounded-lg text-sm h-fit p-1 cursor-pointer"
                onClick={showDetails}
              >
                {open ? "Show less" : "View Details"}
              </div>
            </div>
            <hr />
            <p className={open ? "text-xs py-3" : "text-xs py-3 truncate"}>
              {props.description}
            </p>
            <div className="flex justify-between">
              <div className="w-1/5">
                <input
                  className="m-0 h-8 p-1"
                  type="number"
                  value={quantity || ""}
                  onChange={(e) => {
                    setQuantity(e.target.value);
                  }}
                />
              </div>
              <div
                className="rounded-lg text-sm h-fit p-2 cursor-pointer bg-green-400"
                onClick={addToCart}
              >
                ADD TO CART
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
