import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import bin from "../../assets/bin.png";
import { getUserByEmail } from "../../services/Auth/User";
import { getCart } from "../../services/Cart/GetCart";
import { auth } from "../../Firebase";
import DeleteFromCart from "../../services/Cart/DeleteFromCart";
import Order from "../../services/Cart/Order";
import { ClearCart } from "../../services/Cart/Order";

export default function Cart() {
  const [email, setEmail] = useState();
  const [cart, setCart] = useState([]);
  const [total, setTotal] = useState(0);
  const [userId, setUserId] = useState();
  const [update, setUpdate] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setEmail(auth.currentUser.email);
    }, 1000);

    getUserByEmail(email)
      .then(function (response) {
        let userId = response[0].id;
        setUserId(userId);
        return userId;
      })
      .then(function (userId) {
        getCart(userId).then(function (response) {
          setCart(response[0].cart);
          updateTotal(response[0].cart);
        });
      });
  }, [email, update]);
  function updateTotal(cart) {
    let sum = 0;
    cart.map((product) => {
      sum += product.price * product.quantity;
      return 0;
    });
    setTotal(sum);
  }
  function order() {
    Order(userId, total);
  }
  return (
    <div>
      <Navbar />
      <div className="mb-64">
        <table className="table table-striped">
          <thead className="thead-dark">
            <tr>
              <th scope="col">#</th>
              <th scope="col">Name</th>
              <th scope="col">Price</th>
              <th scope="col">Quantity</th>
              <th scope="col">Delete</th>
              <th scope="col">Total</th>
            </tr>
          </thead>
          <tbody>
            {cart?.map((product, i) => {
              return (
                <tr key={i}>
                  <th scope="row">{i + 1}</th>
                  <td>{product.name}</td>
                  <td>{product.price}</td>
                  <td>{product.quantity}</td>
                  <td className="cursor-pointer">
                    <img
                      src={bin}
                      alt="delete"
                      height="20"
                      width="20"
                      onClick={(e) => {
                        DeleteFromCart(
                          product.name,
                          product.price,
                          product.quantity,
                          userId
                        );
                        setUpdate(update + 1);
                      }}
                    />
                  </td>
                  <td>{product.price * product.quantity}</td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <div className="flex flex-col justify-end items-end px-4">
          <div className="py-2">TOTAL AMOUNT PAYABLE - {total}</div>
          <button
            className="border rounded-md p-1 w-fit my-2"
            onClick={() => {
              ClearCart(userId);
            }}
          >
            CLEAR CART
          </button>
          <button className="border rounded-md p-1 w-fit" onClick={order}>
            ORDER
          </button>
        </div>
      </div>

      <div className="fixed bottom-0 left-0 w-full">
        <Footer />
      </div>
    </div>
  );
}
