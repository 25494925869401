import React, { useState, useEffect } from "react";
import { getSales } from "../../services/Sales/sales";

export default function ProductsTable() {
  const [sales, setSales] = useState();
  useEffect(() => {
    getSales().then((response) => setSales(response));
  }, []);
  return (
    <div className="flex flex-col justify-center items-start m-2 pt-2">
      <div className="text-4xl py-2 text-center w-full">SALES</div>
      <table className="table table-striped">
        <thead className="thead-dark">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Price</th>
            <th scope="col">Quantity</th>
          </tr>
        </thead>
        <tbody>
          {sales?.map((sale, i) => {
            return (
              <tr key={i}>
                <th scope="row">{i + 1}</th>
                <td>{sale.name}</td>
                <td>{sale.price}</td>
                <td className="cursor-pointer">{sale.quantity}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
