import React, { useState } from "react";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import cart from "../assets/shopping-cart.png";
import box from "../assets/box.png";
import admin from "../assets/setting.png";
import exit from "../assets/exit.png";

export default function Navbar() {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 801);
  const logout = () => {
    Swal.fire({
      position: "top",
      icon: "success",
      title: "Successfully Logged Out",
      showConfirmButton: false,
      timer: 1500,
    });
    setTimeout(() => {
      localStorage.setItem("user", "");
      localStorage.setItem("admin", "");
      window.location.href = "/";
    }, 1500);
  };
  return (
    <div>
      {isDesktop ? (
        <div className="w-full h-fit bg-slate-800 flex justify-between items-center px-4 py-2">
          <Link to="/home" className="text-white text-2xl py-2">
            Wholesale Management
          </Link>
          <div>
            <Link to="/cart" className="text-white px-4">
              <img
                src={cart}
                alt="cart"
                height="25"
                width="25"
                className="inline"
              />
            </Link>
            <Link to="/orders" className="text-white">
              <img
                src={box}
                alt="cart"
                height="25"
                width="25"
                className="inline"
              />
            </Link>
            <Link to="/admin" className="text-white px-4">
              Admin
            </Link>
            <button
              onClick={logout}
              className="bg-slate-800 text-white text-sm font-light border border-white p-2 rounded-lg cursor-pointe"
            >
              Logout
            </button>
          </div>
        </div>
      ) : (
        <>
          <div className="w-full h-fit bg-slate-800 flex flex-col items-center">
            <Link to="/home" className="text-white text-xl py-3">
              Wholesale Management
            </Link>
            <div className="w-full flex justify-evenly pb-2">
              <Link to="/cart" className="text-white px-4">
                <img
                  src={cart}
                  alt="cart"
                  height="25"
                  width="25"
                  className="inline"
                />
              </Link>
              <Link to="/orders" className="text-white">
                <img
                  src={box}
                  alt="cart"
                  height="25"
                  width="25"
                  className="inline"
                />
              </Link>
              <Link to="/admin" className="text-white px-4">
                <img
                  src={admin}
                  alt="cart"
                  height="25"
                  width="25"
                  className="inline"
                />
              </Link>
              <img
                src={exit}
                alt="cart"
                height="25"
                width="25"
                className="inline"
                onClick={logout}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
