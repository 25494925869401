import React from "react";
import "./PageNotFound.css";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";

export default function PageNotFound() {
  return (
    <div>
      <section className="page_404 mb-32">
        <div className="w-full justify-center items-center">
          <div className="row flex w-full justify-center items-center">
            <div className="flex w-full justify-center items-center">
              <div className="text-center">
                <div className="four_zero_four_bg">
                  <h1 className="text-center">404</h1>
                </div>

                <div className="contant_box_404">
                  <h3 className="h2">Looks like you're lost</h3>

                  <p>the page you are looking for is not available!</p>

                  <Link to="/home" className="link_404">
                    Go to Home
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="fixed bottom-0 left-0 w-full">
        <Footer />
      </div>
    </div>
  );
}
