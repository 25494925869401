import React, { useEffect, useState } from "react";
import { getUserByEmail } from "../../services/Auth/User";
import { auth } from "../../Firebase";
import { GetUserOrdersByUserId } from "../../services/Cart/Order";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

export default function MyOrders() {
  const [email, setEmail] = useState();
  const [orders, setOrders] = useState([]);
  useEffect(() => {
    setTimeout(() => {
      setEmail(auth.currentUser.email);
    }, 1000);

    getUserByEmail(email)
      .then(function (response) {
        let userId = response[0].id;
        return userId;
      })
      .then(function (userId) {
        GetUserOrdersByUserId(userId).then(function (response) {
          setOrders(response);
          console.log(response);
        });
      });
  }, [email]);
  return (
    <div>
      <Navbar />
      <div className="text-2xl text-center py-4 font-medium">MY ORDERS</div>
      <div className="mb-64">
        {orders ? (
          orders.map((order, i) => {
            return (
              <div>
                <div className="text-black px-4">ORDER #{i + 1}</div>
                <table className="table table-dark table-striped my-4">
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Name</th>
                      <th scope="col">Price</th>
                      <th scope="col">Quantity</th>
                      <th scope="col">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {order.cart?.map((item, i) => {
                      return (
                        <tr key={i}>
                          <th scope="row">{i + 1}</th>
                          <td>{item.name}</td>
                          <td>{item.price}</td>
                          <td>{item.quantity}</td>
                          <td>{item.price * item.quantity}</td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>Grand Total - {order.total}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            );
          })
        ) : (
          <div>NO ORDERS FOUND</div>
        )}
      </div>
      <div className="fixed bottom-0 left-0 w-full">
        <Footer />
      </div>
    </div>
  );
}
