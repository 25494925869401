import { async } from "@firebase/util";
import {
  collection,
  doc,
  addDoc,
  getDocs,
  query,
  where,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../Firebase";

export default async function addToSale(name, price, quantity) {
  const salesCollectionRef = collection(db, "sales");
  const q = query(salesCollectionRef, where("name", "==", name));
  const querySnapshot = await getDocs(q);
  if (
    querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })).length > 0
  ) {
    let sales = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }))[0];
    let oldQty = Number(sales.quantity);
    let newQty = Number(oldQty) + Number(quantity);
    const saleRef = doc(db, "sales", sales.id);
    await updateDoc(saleRef, {
      quantity: newQty,
    });
  } else {
    try {
      const docRef = await addDoc(collection(db, "sales"), {
        name: name,
        price: price,
        quantity: quantity,
      });
      console.log("Document written with ID: ", docRef.id);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  }
}

export async function getSales() {
  const salesCollectionRef = collection(db, "sales");
  const data = await getDocs(salesCollectionRef);
  return data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
}
