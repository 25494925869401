import React from "react";
import Navbar from "../../components/Navbar";
import AddProducts from "./AddProduct";
import Orders from "./Orders";
import ProductsTable from "./ProductsTable";
import Sales from "./Sales";

export default function Admin() {
  return (
    <div>
      <Navbar />
      <div className="flex flex-col md:flex-row">
        <AddProducts className="flex md:w-1/3" />
        <ProductsTable className="flex md:w-2/3" />
      </div>
      <Orders />
      <Sales />
    </div>
  );
}
