import React, { useState, useRef } from "react";
import support from "../assets/customer-support.png";
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";

export default function Footer() {
  const [openHelp, setOpenHelp] = useState(false);
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_41qugfn",
        "template_xsea0mp",
        form.current,
        "Ps9AeqifbvvsMfQ9t"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    e.target.reset();
    setOpenHelp(false);
    Swal.fire({
      title: "Thank You",
      text: "Our support team will get in touch with you soon",
    });
  };
  return (
    <div className="bg-slate-800">
      <div className="w-full h-fit bg-slate-800 flex flex-col md:flex-row justify-between items-center py-4 md:px-32 text-white">
        <div className="py-2">
          Copyright &copy; 2023 John Doe, All Rights Reserved
        </div>
        <div
          className="cursor-pointer"
          onClick={() => {
            setOpenHelp(true);
          }}
        >
          Help and Support
          <img
            src={support}
            alt="support"
            height="35"
            width="35"
            className="inline mx-2"
          />
        </div>
      </div>
      {openHelp && (
        <form
          ref={form}
          onSubmit={sendEmail}
          className="text-white flex justify-center flex-col items-center pb-4"
        >
          <div className="flex flex-col my-1 w-1/2 md:w-1/4">
            <label>Name</label>
            <input
              type="text"
              name="name"
              className="h-8 bg-white rounded text-black p-1"
            />
          </div>
          <div className="flex flex-col my-1 w-1/2 md:w-1/4">
            <label>Email</label>
            <input
              type="email"
              name="email"
              className="bg-white rounded p-1 text-black"
            />
          </div>
          <div className="flex flex-col my-1 w-1/2 md:w-1/4">
            <label>Subject</label>
            <input
              type="text"
              name="subject"
              className="bg-white rounded p-1 text-black"
            />
          </div>
          <div className="flex flex-col w-1/2 md:w-1/4">
            <label>Message</label>
            <textarea
              name="message"
              className="bg-white rounded p-1 text-black"
            />
          </div>
          <input
            type="submit"
            value="Send"
            className="border border-1 p-2 rounded"
          />
        </form>
      )}
    </div>
  );
}
