import React, { useState, useEffect } from "react";
import { getProducts } from "../../services/Product/GetProducts";
import bin from "../../assets/bin.png";
import deleteProduct from "../../services/Product/DeleteProduct";

export default function ProductsTable() {
  const [products, setProducts] = useState();
  useEffect(() => {
    getProducts().then(function (response) {
      setProducts(response);
    });
  }, []);
  return (
    <div className="flex flex-col justify-center items-start md:w-2/3 m-2 pt-2">
      <div className="text-4xl py-2 text-center w-full">ALL PRODUCTS</div>
      <table className="table table-striped">
        <thead className="thead-dark">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Price</th>
            <th scope="col">Delete</th>
          </tr>
        </thead>
        <tbody>
          {products?.map((product, i) => {
            return (
              <tr key={i}>
                <th scope="row">{i + 1}</th>
                <td>{product.name}</td>
                <td>{product.price}</td>
                <td className="cursor-pointer">
                  <img
                    src={bin}
                    alt="delete"
                    height="20"
                    width="20"
                    data-id={product.id}
                    onClick={(e) => {
                      deleteProduct(e.target.getAttribute("data-id"));
                    }}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
